import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/message-management',
  component: Homelayout,
  children: [
    {
      path: 'data-collection',
      name: 'data-collection',
      component: () => import('@/views/message-management/data-collection.vue'),
      meta: { title: '数据收集' }
    },
    {
      path: 'system-announcement',
      name: 'system-announcement',
      component: () => import('@/views/message-management/system-announcement.vue'),
      meta: { title: '制度公告' }
    },
    {
      path: 'knowledge-question-bank',
      name: 'knowledge-question-bank',
      component: () => import('@/views/message-management/knowledge-question-bank.vue'),
      meta: { title: '知识题库' }
    }
  ]
}]
